.markdown {
    @apply t__body--b3;
}
.thumbnail img {
    @apply rounded-md;
}
.markdown h1 {
    @apply mt-2 mb-4 t__heading--h4;
}

.markdown h2 {
    @apply mt-2 t__heading--h2;
}

.markdown h3 {
    @apply mt-2 t__heading--h5;
}

.markdown h4 {
    @apply mt-2 t__heading--h6;
}

.markdown h5 {
    @apply mt-2 t__heading--h5;
}

.markdown h6 {
    @apply mt-2 t__heading--h6;
}

.markdown ul {
    @apply pl-4 list-disc list-inside;
}
.markdown ol {
    @apply pl-4 list-decimal list-inside;
}

.markdown > ul,
.markdown > ol {
    @apply pl-0;
}
.markdown code {
    @apply inline-block px-1 font-mono rounded-md ring-1 ring-grey-80 bg-grey-90;
}

.markdown blockquote {
    @apply px-2 py-1 text-white border-l-4 border-grey-70 bg-grey-90/50;
}

.markdown a {
    @apply text-main-30 hover:underline underline-offset-2;
}

.markdown iframe {
    @apply w-full rounded-lg;
}
