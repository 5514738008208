@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .backdrop-blur {
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .backdrop-blur {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

#loader-spinner {
    @apply animate-spin;
}

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@layer components {
    .card {
        @apply rounded-2xl ring-1 bg-grey-60 ring-grey-50;
    }
    .card-transparent {
            @apply rounded-2xl ring-1 bg-grey-60/50 ring-grey-50;
    }
    .card__border {
        @apply border-[#2b2038] border-[1px];
    }
    .mh-button {
        @apply flex items-center gap-2 py-2 pl-2 pr-3 rounded-lg;
    }
    .dropdown__container {
        @apply shadow-lg bg-main-85 ring-1 ring-main-90;
    }
    .input--primary {
        @apply ring-1 ring-transparent focus:ring-main-50;
    }
}

body {
    @apply min-h-screen text-grey-20 bg-[#130f1b];
}
body.bg-plain {
    @apply to-grey-100 from-grey-100;
}
.big-underline {
    text-underline-offset: 0.25em;
}

.card-arrow {
    @apply h-8 w-8 rounded-full ring-1 bg-neutral-10 ring-neutral-30 mx-auto !-mb-12 translate-y-[-2rem] flex items-center justify-center;
}

.card__action {
    @apply ring-1 ring-white/[0.05] bg-black/[0.5] shadow-lg md:shadow-card-action;
}

.nav__item--flat {
    @apply relative cursor-pointer text-grey-10 t__button--u1 hover:text-main-40;
}
.nav__item_exchange--flat {
    @apply relative cursor-pointer text-grey-10 t__button--u1 hover:text-main-30;
}
.nav__item_missionhub--flat {
    @apply relative cursor-pointer text-grey-10 t__button--u1 hover:text-main-40;
}
.mobile-nav__item {
    @apply text-left p-2 pr-4 t__button--u1 hover:bg-[#1f2232] rounded-lg w-36 text-white;
}
.bg--highlight {
    @apply bg-gradient-to-l from-[#4929ad] to-[#e50146] text-white disabled:filter disabled:grayscale disabled:pointer-events-none;
}

@media only screen and (max-width: 768px) {
    .hide-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

/* Hiding arrows in input[type=number] */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number']:not(.input-number) {
    -moz-appearance: textfield;
}
.Toastify {
    --toastify-icon-color-success: theme('colors.green.70');
    --toastify-color-progress-success: theme('colors.green.70');
    --toastify-color-progress-error: theme('colors.red.60');
    --toastify-color-progress-error: theme('colors.red.60');
    --toastify-color-dark: theme('colors.grey.50');
    --toastify-toast-width: 380px;
}
.Toastify__toast {
    @apply !shadow-toast bg-[#171a27] text-neutral-80 !rounded-lg;
}
.Toastify__toast-body {
    @apply font-body font-medium items-center justify-center !px-4;
}
.Toastify__toast-body > div:last-child {
    @apply !flex-none;
}
.Toastify__progress-bar {
    @apply rounded-full;
}
.Toastify__close-button {
    @apply text-neutral-80;
}
.Toastify__toast-body > div:last-child {
    flex: 1 !important;
}

.arrow--top {
    position: relative;
    @apply bg-neutral-80;
}
.arrow--top:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(68, 65, 71, 0);
    border-bottom-color: #fff;
    border-width: 12px;
    margin-left: -12px;
}
.arrow--top:after {
    border-bottom-color: theme('colors.grey.80');
}

.tippy-tooltip-content {
    @apply font-body t__caption--c1;
}
.tippy-popper .tippy-tooltip {
    @apply bg-grey-10 text-grey-90 rounded-lg !important;
}
.tippy-popper[x-placement^='bottom'] .tippy-tooltip [x-arrow] {
    border-bottom-color: theme('colors.grey.10') !important;
}
.tippy-popper[x-placement^='top'] .tippy-tooltip [x-arrow] {
    border-top-color: theme('colors.grey.10') !important;
}
.tippy-popper[x-placement^='left'] .tippy-tooltip [x-arrow] {
    border-left-color: theme('colors.grey.10') !important;
}
.tippy-popper[x-placement^='right'] .tippy-tooltip [x-arrow] {
    border-right-color: theme('colors.grey.10') !important;
}

.tippy-tooltip {
    @apply rounded-lg;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 2px;
}
::-webkit-scrollbar-thumb {
    background: theme('colors.grey.50');
    border: 0px none #ffffff;
    border-radius: 8px;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background: #3d4264;
}
::-webkit-scrollbar-thumb:active {
    background: #3d4264;
}
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
}
::-webkit-scrollbar-track:active {
    background: #212438;
}
::-webkit-scrollbar-corner {
    background: transparent;
}

.leaderboard-table {
    @apply w-full text-grey-20;
}
.leaderboard-header {
    @apply px-4 py-3 mb-4 text-sm rounded-lg t__button--u2 bg-grey-50 text-grey-20;
}
.leaderboard-header > span {
    @apply pr-2;
}

.leaderboard-body > div {
    @apply py-2 mb-1 text-base font-medium rounded-lg bg-grey-50 text-grey-10;
}
.leaderboard-body > div > span {
    @apply pl-4;
}

input[type='range'] {
    border-radius: 8px;
    height: 4px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 50%;
    background: #434343;
    overflow: visible;
    position: relative;
    cursor: pointer;
}

input[type='range']:focus {
    outline: none;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='range'] {
        width: 100%;
        -webkit-appearance: none;
    }

    input[type='range']::-webkit-slider-runnable-track {
        height: 4px;
        -webkit-appearance: none;
        color: linear-gradient(to right, #ff930f 0%, #fff95b 100%);
        margin-top: -4px;
    }

    input[type='range']::-webkit-slider-thumb {
        width: 8px;
        @apply rounded-full;
        -webkit-appearance: none;
        height: 8px;
        cursor: ew-resize;
        background: #fff95b;
    }
}
/** FF*/
input[type='range']::-moz-range-thumb {
    width: 8px;
    -webkit-appearance: none;
    height: 8px;
    cursor: ew-resize;
    background-color: #fff95b;
    @apply rounded-full;
}
input[type='range']::-moz-range-progress {
    @apply bg-gradient-to-r from-[#ff930f] to-[#fff95b];
}
input[type='range']::-moz-range-track {
    background-color: #10111b;
}
/* IE*/
input[type='range']::-ms-fill-lower {
    @apply bg-gradient-to-r from-[#ff930f] to-[#fff95b];
}
input[type='range']::-ms-fill-upper {
    background-color: #10111b;
}
.line {
    height: 2px;
    background: linear-gradient(to right, transparent 50%, #223049 50%), transparent;
    background-size: 20px 2px, 100% 22px;
}

.faq__content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: 'read more';
}

.mh-button--base {
    @apply mh-button bg-grey-10 hover:bg-grey-20;
}
.mh-button--twitter {
    @apply mh-button text-white bg-[#38acff] hover:bg-opacity-80;
}

/*Shimmer text */
:root {
    --default-bg-color: @apply bg-[rgb(37, 31, 45) ];
    --default-bg-moving-gradient: linear-gradient(
        to right,
        rgba(37, 31, 45, 0.5) 8%,
        rgba(37, 31, 45, 0.9) 18%,
        rgba(37, 31, 45, 0.5) 33%
    );
}

.shimmer {
    background: var(--default-bg-color);
    background-image: var(--default-bg-moving-gradient);
    background-repeat: no-repeat;
    animation: shimmering forwards infinite ease-in-out, fadein 0.02s forwards;
    @apply rounded-full;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes shimmering {
    from {
        background-position: top right;
    }

    to {
        background-position: top left;
    }
}

.gr--blue {
    left: 45%;
    top: 120px;
    position: relative;
    width: 50vw;
    height: 50vh;
    filter: blur(75px);
    background: rgb(87, 0, 254);
    background: radial-gradient(
        circle,
        rgba(87, 0, 254, 1) 0%,
        rgba(87, 0, 254, 0.1334908963585434) 100%
    );
}

.gr--red {
    top: 10%;
    right: 10%;
    position: absolute;
    width: 25%;
    height: 15%;
    background: rgb(255, 0, 0);
}

input[type="radio"] {
    -webkit-appearance: checkbox;
    /* Chrome, Safari, Opera */
    -moz-appearance: checkbox;
    /* Firefox */
    -ms-appearance: checkbox;
    /* not currently supported */
}

.index-gradient {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle 70vw at bottom,
            #763be35b 5%,
            #2606534c 50%,
            #150e2027 85%);
}

.game-gradient {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle 50vw at center,
            #763be35b 5%,
            #2606534c 50%,
            #150e2027 85%);
}

.simple--gradient {
    background-size: auto, cover;
    background-position: center;
    width: 30%;
    height: 75%;
    background: radial-gradient(circle,
            rgb(79, 20, 63, 0.9) 25%,
            rgba(53, 20, 63, 0.8) 50%,
            rgba(28, 21, 35, 1) 85%);
}
.exchange-gradient{
    width: 100%;
    height: 100%;
    background: radial-gradient(circle 100vw at bottom,
                #330A38 25%,
                #201126 50%,
                #1D1524 85%);
}
.market-gradient {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle 60vw at bottom,
            #00f5e54c 5%,
            #0db7b725 40%,
            #075b5b25 85%);
}
.pool-gradient {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle 50vw at bottom,
            #6574fb7c 25%,
            #262f8088 50%,
            #070c32 85%);
}
.leaf{
    border-radius: 5px 20px;
    transform: rotate(90deg);
}
/*This is for text not to be selectable*/
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}