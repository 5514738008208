@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('HelveticaNeueLTPro-Roman.eot');
    src: url('HelveticaNeueLTPro-Roman.eot?#iefix') format('embedded-opentype'),
        url('HelveticaNeueLTPro-Roman.woff2') format('woff2'),
        url('HelveticaNeueLTPro-Roman.woff') format('woff'),
        url('HelveticaNeueLTPro-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

